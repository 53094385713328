.download-search-results-button {
  margin: 10px !important;
  background-color: var(--shared-colors-napa-gold-10, #FFC72C) !important;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
  border-color: inherit !important;
  font-size: 1px !important;
}

.download-search-results-button-disabled {
  margin: 10px !important;
  background-color: rgba(0, 0, 0, 0.10) !important;
  color: rgba(0, 0, 0, 0.30) !important;
}

.download-anchor {
  text-decoration: none;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
}
