.regulation-header{
    display: flex;
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    background: var(--shared-colors-napa-blue-10, #001489);
}

.regulation-title {
    color: var(--shared-colors-napa-white, #FFF);
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 10px;
}

.app-section {
    display: inline-flex;
}

.app-logo {
    height: 40px;
}

.user-section {
    display: inline-flex;
}

.user-logo {
    display: flex;
    align-items: center;
    margin-right: 4px;
}

.user-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0.36px;
    color: var(--shared-colors-napa-white, #FFF);
}

.divider-icon {
    fill: var(--shared-colors-napa-blue-6, #6672B8);
    padding: 4px;
}

.logout-button-primary {
    margin: 10px !important;
    background-color: var(--shared-colors-napa-gold-10, #FFC72C) !important;
    color: var(--shared-colors-napa-blue-10, #001489) !important;
    border-color: inherit !important;
}
  
.logout-button-secondary {
    margin: 10px !important;
    background-color: var(--shared-colors-napa-blue-10, #001489) !important; 
    color: var(--shared-colors-napa-white, #FFFFFF) !important;
    border-color: inherit !important;
}

.logoutDialog {
    font-weight: 500 !important;
    line-height: 1.25 !important;
    letter-spacing: 0.02857em !important;
    color: var(--shared-colors-napa-gray-10, #333333) !important;
}

.logout-dialog-description {
    font-weight: 500 !important;
    line-height: 1.25 !important;
    letter-spacing: 0.02857em !important;
    color: var(--shared-colors-napa-gray-10, #333333) !important;
}
