.regulation-container {
  background: var(--shared-colors-napa-white, #FFFFFF);
  padding: 20px;
}

.regulation-list-title {
  color: var(--shared-colors-napa-blue-10, #001489);
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 44px;
}

.regulation-list-filters {
  padding-top: 15px;
}

.regulation-table {
  padding: 15px 0;
}

.filter-container {
  border-radius: 15px;
  background: var(--shared-colors-napa-gray-1, #f4f4f4);
  height: 85px;
  display: flex;
  align-items: center;
}

.regPartsTitle {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color:  var(--shared-colors-napa-blue-10, #001489);
  margin: 25px 0;
}

.regDetailsTitle {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  color: var(--shared-colors-napa-blue-10, #001489);
  margin-top: 50px;
}

.sortParts {
  margin-right: 5px;
}

.parts-filter-section {
  display: flex;
  margin-bottom: 10px;
}

.dialog-button-primary {
  margin: 10px !important;
  background-color: var(--shared-colors-napa-gold-10, #FFC72C) !important;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
  border-color: inherit !important;
}

.dialog-button-secondary {
  margin: 10px !important;
  background-color: var(--shared-colors-napa-blue-10, #001489) !important; 
  color: var(--shared-colors-napa-white, #FFFFFF) !important;
  border-color: inherit !important;
}

.alert-dialog-title {
  font-weight: 600 !important;
  line-height: 1.25 !important;
  letter-spacing: 0.02857em !important;
  color: var(--shared-colors-napa-blue-10, #001489) !important;
}

.alert-dialog-description {
  font-weight: 500 !important;
  line-height: 1.25 !important;
  letter-spacing: 0.02857em !important;
  color: var(--shared-colors-napa-gray-10, #333333) !important;
}

.MuiDataGrid-aggregationColumnHeader {
  background-color: var(--shared-colors-napa-blue-10, #001489) !important;
}

.MuiDataGrid-columnHeaderTitle {
  font-weight: 500 !important;
  color: var(--shared-colors-napa-gray-10, #333333) !important;
}

.MuiDataGrid-withBorderColor {
  border-color: var(--shared-colors-napa-gray-3, #C1C1C1) !important;
}

.MuiDrawer-paper {
  border: 2px solid !important;
  border-top-color: var(--shared-colors-napa-blue-10, #001489) !important;
  padding: 25px !important;
  width: 80% !important;
}

.MuiAccordion-root{
  border: 1px solid !important;
  border-top-color: var(--shared-colors-napa-blue-10, #001489) !important;
  padding: 10px !important;
  width: auto !important;
}