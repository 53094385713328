.form-group-flex {
    display: flex;
}

.update-regulation-form {
    border-radius: 33px;
    background: var(--shared-colors-napa-white, #FFF);
    box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.25);
    width: 95%;
    padding: 25px;
    margin-top: 20px;
}

.inValidRow {
    background-color: red !important
}

.update-regulated-parts-section{
    margin: 25px 0;
    border-top: 1px solid;
    border-color: var(--shared-colors-napa-gray-3, #C1C1C1) !important;
}

.update-parts-filter-section {
    display: flex;
    margin-bottom: 10px;
}

.editableTextField .MuiOutlinedInput-notchedOutline > fieldset{
    border: none !important;
}
