.button {
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  color: var(--napa-white);
  display: flex;
  border: none;
  font-family: var(--headings-font);
  font-size: var(--rem-04);
  height: var(--rem-15);
  outline: none;
  padding: 0;
  position: relative;
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.button:after {
  border-bottom: 6px solid var(--napa-yellow-1000);
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  transition: 0.3s;
  width: 0;
}

.button:hover::after,
.button:focus::after {
  width: 100%;
}

.img {
  margin-left: var(--rem-02);
}
